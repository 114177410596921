import React from "react";
import ClassSearch from "app/components/Algolia/ClassSearch";
import { useFamilyFriendlyFlag } from "hooks/useFeatureFlags";
import { AlgoliaIndexes } from "app/components/Algolia/types";
import { useResetInitialPagination } from "hooks/useResetInitialPagination";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { ResultsBodyWrapper } from "../ResultsBodyWrapper";

export default () => {
  const isFamilyFeatureEnabled = useFamilyFriendlyFlag();
  const { resettingPagination } = useResetInitialPagination();

  if (resettingPagination) {
    return <LoaderCentered />;
  }

  return (
    <ResultsBodyWrapper
      contentType="classes"
      contentTitle="Classes"
      indexName={AlgoliaIndexes.classes}
    >
      <ClassSearch isFamilyFeatureEnabled={isFamilyFeatureEnabled} />
    </ResultsBodyWrapper>
  );
};
